import { FC } from "react"

interface SourceIconProps{
  sourceId:number
}

const SourceIconPath: {[key:number]: string} = {
    1: "https://www.avito.st/dstatic/favicon.ico",
    3: "https://balashikha.cian.ru/favicon.ico"
}

export const SourceIcon:FC<SourceIconProps> = ({ sourceId }) => {
    return(
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <img style={{ height: "18px" }} src={SourceIconPath?.[sourceId]}/>
    </div>
    )
}