import { FC } from "react"

import css from "./PhoneCell.module.scss"

interface PhoneCellProps {
  isPaid: number
}

export const PhoneCell:FC<PhoneCellProps> = ({ isPaid,children }) => {
    let PhoneCellClass = css.default
    switch(isPaid){
        case 1:{
            PhoneCellClass = css.payCall
            break
        }
        case 2:{
            PhoneCellClass = css.notCall
            break
        }
    }
    return(
    <div className={PhoneCellClass}>
      {children}
    </div>
    )
}